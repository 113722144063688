* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: azure;
}

.App {
  text-align: center;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
